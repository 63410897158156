import React, { useEffect } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import { legendColor } from "d3-svg-legend";

export default function CountyMap({ data, countyData }) {
  const height = 650;
  const width = 900;
  const us = countyData;
  const populationData = data;
  const countyShapes = topojson.feature(us, us.objects.counties);
  const statesMesh = topojson.mesh(us, us.objects.states, (a, b) => a !== b);
  const populationByCounty = new Map(populationData.slice(1).map(row => [String(row[1]) + String(row[2]), Number(row[0])]));

  const populationColor = d3.scaleSequentialLog()
    .domain(d3.extent(populationData.slice(1), d => +d[0]))
    .interpolator(d3.interpolateGreens);

  const populationFormat = d3.format(",d");

  useEffect(() => {
    const svg = d3.select("#chart")
      .attr("width", width)
      .attr("height", height);

    const g = svg.select("g");

    if (!g.node()) {
      svg.append("g");
    }

    svg.selectAll("*").remove();

    const zoom = d3.zoom()
      .scaleExtent([1, 20])
      .on("zoom", zoomed);

    svg.call(zoom);

    const mapFeatures = svg.append("g");

    const projection = d3.geoAlbersUsa()
      .fitExtent([[0, 0], [width, height]], countyShapes);

    const path = d3.geoPath().projection(projection);

    const xScale = d3.scaleBand().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    const marginBottom = 20; // Add the marginBottom variable and adjust the value as needed

    mapFeatures.selectAll("path")
      .data(countyShapes.features)
      .join("path")
      .attr("fill", d => {
        const population = populationColor(populationByCounty.get(String(d.id)))
        return population || "white";
      })
      .attr("d", path)
      .attr("class", "county-outline")
      .attr("stroke", "#aaaaaa")
      .append("title")
      .text(d => {
        const population = populationByCounty.get(String(d.id));
        const countyPopulation = population ? populationFormat(population) : "No data available";
        return `${d.properties.name}, ${countyPopulation}`;
      });

    svg.append("path")
      .datum(statesMesh)
      .attr("class", "states-outline")
      .attr("fill", "none")
      .attr("stroke", "#222222")
      .attr("stroke-linejoin", "round")
      .attr("d", path);

    var values = populationColor.ticks(10).map(function(value) {
      return populationFormat(value); 
    });

    var longest = d3.max(values, function(value) {
      return value.length;
    });

    var shapeWidth = longest * 10;

    svg.append("g")
      .attr("class", "legend")
      .attr('transform', "translate(0," + (height - 40) + ")");
    d3.select(".legend").call(legendColor()
      .shapeWidth(shapeWidth)
      .cells(10)
      .orient("horizontal")
      .scale(populationColor)
      .labelFormat(d3.format(",d"))
    );

    function zoomed(event) {
      const t = event.transform;
      const updatedXScale = xScale.copy().range([0, width * t.k]);
      const updatedYScale = yScale.copy().range([height, 0]).domain([0, yScale.domain()[1] / t.k]).nice();
    
      mapFeatures.attr("transform", t);
      
      svg.select(".states-outline")
      .attr("transform", t)
      .attr("stroke-width", Math.max(0.5 / t.k, 0.5));
    
      svg.select(".x-axis")
        .attr("transform", "translate(" + t.x + "," + (height - marginBottom) + ")")
        .call(d3.axisBottom(updatedXScale));
    
      svg.select(".y-axis")
        .call(d3.axisLeft(updatedYScale).tickValues(updatedYScale.ticks()));
    }

    return () => {
      svg.on(".zoom", null);
    };

  }, [countyShapes, populationColor, populationByCounty, statesMesh, width, height]);

  return null;
}
