import React from "react";
import ChartOne from "../components/BarRace/RacingBarChart";


function BarRacePanel() {

    return (
        <div>
            
            <div>
                <ChartOne ></ChartOne>
            </div>
        </div>
    );

}

export default BarRacePanel;
