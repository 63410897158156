/** @format */

import React, { useState } from "react";
import SecondPage from "./SecondPage";
import Modal from "react-bootstrap/Modal";
import "./VisualModal.css";

// const [modalShow, setShow] = useState("false");

export default class VisualModal extends React.Component {
	onClose = (e) => {
		this.props.show && this.props.onClose(e);
	};

	render() {
		if (!this.props.show) {
			return null;
		}

		return (
			<Modal
				show={this.props.show}
				onHide={() => this.onClose()}
				dialogClassName='custom-modal'
				aria-labelledby='example-custom-modal-styling-title'>
				<Modal.Header closeButton>
					{/* <Modal.Title id='example-custom-modal-styling-title'>
						
					</Modal.Title> */}
				</Modal.Header>
				<Modal.Body className='p-5'>
					<SecondPage id={this.props.id} />
				</Modal.Body>
			</Modal>
		);
	}
}
