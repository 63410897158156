import React from "react";
import "./Share.css";
import SocialShareButtons from "../SocialShareButtons/SocialShareButtons";

const Share = (props) => {

    let origin = new URL(window.location.href).origin;
    let pathname = new URL(window.location.href).pathname;
    let url = origin + pathname;
    
    let SingleCriteria = "";
    let DP2 = "";

    if(url.includes("?")){
        return (
            <div>
                The data is: {url}
            </div>
        );
    }

    if(props.IsSingleCriteria == true && props.DP1Value){
        SingleCriteria = "&DP1Value=" + props.DP1Value;
    }

    if(props.DP2){
        DP2 = "&DP2=" + props.DP2;
    }

    let shareURL = url + "?DP1=" + props.DP1 + SingleCriteria + DP2;

    function Clipboard(){
        let copiedText = document.querySelector('#urlCopy');
        navigator.clipboard.writeText(copiedText.innerText);

        document.querySelector('.shareableFeedback').classList.remove('hide');
        document.querySelector('.shareableFeedback').classList.add('show');
        setTimeout(function(){
            document.querySelector('.shareableFeedback').classList.add('hide');
            document.querySelector('.shareableFeedback').classList.remove('show');
        }, 2000);
    }

    return (
        <>
            <div onClick={Clipboard} className="shareable">
                <strong>Shareable Link (click to copy):</strong><div id="urlCopy">{shareURL}</div>
                <div className="shareableFeedback hide">
                    <p>URL copied to clipboard!</p>
                </div>
            </div>
            <SocialShareButtons url={shareURL}/>
        </>
    );
};

export default Share;
