import React, { useRef, useEffect, useState} from "react";
import CreatedGroupedBarChart from "./GroupedBar";
import * as d3 from 'd3';
import Utils from "../../helpers/Data";


function GroupedBarChart(props) {  
    
    const svgRef = useRef();    
    const[total, setTotal] = useState(0);

    useEffect(()=>{
      if(total > 0){
          props.IsSvgRendered(true);
      }
  }, [total])
    
    useEffect(()=>{
        if(props.pivot.length!==0){
            d3.selectAll('svg#chart').selectAll('*').remove();
            Promise.all([props]).then(data => {
              let groupedBar = document.querySelector(".y-axis");

                if(!groupedBar){
                  CreatedGroupedBarChart(svgRef, data[0].pivot, {
                    x: d => d.state,
                    y: d => d.population,
                    z: d => d.age,
                    xDomain: Utils.selectSort(data[0].pivot, false),
                    yLabel: "Population",
                    zDomain: data[0].across,
                    colors: d3.schemeSpectral[data[0].across.length],
                    height: 700,
                    width: 720,
                    totalPopulation: Utils.GetTotalPopulationByDoubleCriteria(data[0].pivot)
                  })
                  setTotal(Utils.GetTotalPopulationByDoubleCriteria(data[0].pivot));
                }
            })
        }    
    });

      return (  
        
        <>
            <div>
                {total > 0 ? <div>Total Population: {total}</div> : <div></div>}
                {total > 0 ? <svg ref={svgRef} id="chart"></svg> : <div></div>}
            </div>
        </>
      )  
}  

export default GroupedBarChart;
