import React , { useState, useEffect }from "react";
import Utils from "../../helpers/Data";
import "./SingleCriteria.css";
import $ from "jquery";
import Export from "../Export/Export.js";
import ExportUtils from "../../helpers/ExportHelper";
import Sort from "../Sort/Sort";


function InputArea(props) {

    const [options, setOptions] = useState([]);
    const [optionsSon, setOptionsSon] = useState([]);
    const [optionsNotInclude, setOptionsNotInclude] = useState([]);
    const [triggerOtherSelect, setTriggerOtherSelect] = useState(true);
    const [DP1Selected, setDP1Selected] = useState('');
    const [DP1ValueSelected, setDP1ValueSelected] = useState('');
    const [DP2Selected, setDP2Selected] = useState('');

    let allowDP2 = false;

    const [btnDisabled, setBtnDisabled] = useState(true);
    const [imgFormat, setImgFormat] = useState(false);
    
    let notIncludeValue = $('#notInclude').find(":selected").val();
    let sonValue = $('#son').find(":selected").val();
    let fatherValue = $('#father').find(":selected").val();

    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let DP1QS = "";
    let DP1ValueQS = "";
    let DP2QS = "";

    if(queryString){
        DP1QS = urlParams.get('DP1');
        DP1ValueQS = urlParams.get('DP1Value')
        DP2QS = urlParams.get('DP2');
    }

    function download(dataBase64URL, imageName){
        const link = document.createElement("a");
        link.href = dataBase64URL;
        link.download = imageName;
        link.click();
    }

    useEffect(() => {
        Utils.GetFatherOptions().then(data => {
            console.log('getting father options');
            if($('#father').find('option[value="loading"]').length){
                $('#father').find('option[value="loading"]').remove();
                $('.loading').remove();
            }
            setOptions(data);
        });
      }, []);

    useEffect(() => {
        if(options.length){
            $.each (options, function (index, value) {
                var option = $ ('<option></option>');
                option.val(value.value);
                option.text(value.value);
                $('#father').append(option);
            });

            if(DP1QS){
                querySetFather();
            }
        }
    }, [options]);

    useEffect(() => {
        if(optionsSon.length){
            $.each (optionsSon, function (index, value) {
                var option = $ ('<option></option>');
                option.val(value.value);
                option.text(value.value);
                $('#son').append(option);
            });

            if(DP1QS && DP1ValueQS){
                querySetSon();
            }
        }
    }, [optionsSon]);

    useEffect(() => {
        if(optionsSon.length){
            allowDP2 = true;
            $.each (optionsNotInclude, function (index, value) {
                var option = $ ('<option></option>');
                option.val(value.value);
                option.text(value.value);
                $('#notInclude').append(option);
            });
        }

        if(allowDP2 && DP2QS){
            querySetNotInclude();
        }
    }, [optionsNotInclude]);

    useEffect(() => {
        if(props.IsSvgRendered != false){
            let exportFormat = document.querySelector("#exportFormat").value;

            if(exportFormat.toLowerCase() == 'default'){
                return;
            }

            const fetchData = async () => {
                const FileName = ExportUtils.GenerateFileName(DP1Selected, DP1ValueSelected, DP2Selected);

                if(exportFormat.toLowerCase() != "pdf"){
                    const result = await ExportUtils.GenerateImageLink(exportFormat, FileName);
                    download(result, FileName + "." + exportFormat.toLowerCase());
                } else {
                    const result = await ExportUtils.GenerateImageLink(exportFormat, FileName);
                    return result;
                }
            }

            fetchData();
        }
    }, [imgFormat]);

    // useEffect(() => {
    //     if(DP1QS){
    //         querySetFather();
    //     }
    //   }, []);

    //   useEffect(() => {
    //     if(DP1QS && DP1ValueQS){
    //         querySetSon();
    //     }
    //   }, [optionsSon]);

    // useEffect(() => {
    //     if(allowDP2){
    //         querySetNotInclude();
    //     }
    // }, [optionsNotInclude]);

    const querySetFather = () => {
        let match = false;
        $('#fatherAlert').remove();

        $('#father option').each(function(){
            if(this.value && this.value == DP1QS){
                match = true;
            }
        })

        if(match){
            $('#father').val(DP1QS);
            setDP1Selected(DP1QS);
            props.DP1(encodeURIComponent(DP1QS));  
            // setOptionsSon(Utils.GetSonOptions(DP1QS));
            // setOptionsNotInclude(Utils.GetFatherOptions(DP1QS));
            Utils.GetSonOptions(DP1QS).then(data => {
                if($('#son').find('option[value="loading"]').length){
                    $('#son').find('option[value="loading"]').remove();
                }
                setOptionsSon(data);
            });
            
            if(!DP1ValueQS && !DP2QS){
                props.handleClick();
                return;
            }
            allowDP2 = true;
        } else {
            allowDP2 = false;
            $('#father').after('<div id="fatherAlert" style="color:red">Value for dropdown not valid.</div>');
        }
    }

    const querySetSon = () => {
        if(DP1QS == $('#father').val()){
            let match = false;
            $('#sonAlert').remove();
    
            $('#son option').each(function(){
                if(this.value && this.value == DP1ValueQS){
                    match = true;
                }
            })
    
            if(match){
                if(triggerOtherSelect != false){
                    $('#son').val(DP1ValueQS);
                }
                setDP1ValueSelected(DP1ValueQS);
                props.DP1Value(encodeURIComponent(DP1ValueQS));  
                allowDP2 = true;
                Utils.GetFatherOptions(DP1QS).then(data => {
                    if($('#notInclude').find('option[value="loading"]').length){
                        $('#notInclude').find('option[value="loading"]').remove();
                    }

                    setOptionsNotInclude(data);
                });
                // setOptionsNotInclude(Utils.GetFatherOptions(DP1ValueQS));
            } else {
                allowDP2 = false;
                $('#son').after('<div id="sonAlert" style="color:red">Value for dropdown not valid.</div>');
            }

            return;
        } else {
            props.DP1Value('');
        }
    }

    const querySetNotInclude = () => {
        if(DP2QS != $('#father').val()){
            let match = false;
            $('#notIncludeAlert').remove();

            $('#notInclude option').each(function(){
                if(this.value && this.value == DP2QS){
                    match = true;
                }
            })
            
            if(match){
                if(triggerOtherSelect != false){
                    $('#notInclude').val(DP2QS);
                    setBtnDisabled(false);
                    props.handleClick();
                }
                setDP2Selected(DP2QS);
                props.DP2(encodeURIComponent(DP2QS));
            } else {
                $('#notInclude').after('<div id="notIncludeAlert" style="color:red">Value for dropdown not valid.</div>');
            }

            return;
        } else {
            props.DP2('');
        }
    }

    const handleChangeFather = (event) => {
        event.preventDefault();
        $('#fatherAlert').remove();
        setTriggerOtherSelect(false);
        setDP1Selected(event.target.value);
        props.DP1(encodeURIComponent(event.target.value));
        if($('#father').val() == $('#notInclude').val()){
            props.DP2('');
        }
        Utils.GetSonOptions(event.target.value).then(data => {
            if($('#son').find('option').length){
                $('#son').find('option').each(function(){
                    if($(this).val() != "selectOption"){
                        $(this).remove();
                    }
                })
            }
            setOptionsSon(data);
        });
        // setOptionsSon(Utils.GetSonOptions(event.target.value));
        // setOptionsNotInclude(Utils.GetFatherOptions(event.target.value));
        Utils.GetFatherOptions(event.target.value).then(data => {
            if($('#notInclude').find('option').length){
                $('#notInclude').find('option').each(function(){
                    if($(this).val() != "selectOption"){
                        $(this).remove();
                    }
                })
            }
            setOptionsNotInclude(data);
        });
        if((event.target.value!=="" && sonValue!=="" && notIncludeValue!=="") || (event.target.value!=="" && sonValue==="" && notIncludeValue===""))
            setBtnDisabled(false);
        else
            setBtnDisabled(true);
    }

    const handleChangeSon = (event) => {
        event.preventDefault();
        $('#sonAlert').remove();
        setDP1ValueSelected(event.target.value);
        props.DP1Value(encodeURIComponent(event.target.value));
        if(event.target.value!=="" && fatherValue!=="" && notIncludeValue!=="")
            setBtnDisabled(false);
        else
            setBtnDisabled(true);
    }

    const handleChangeNotInclude = (event) => {
        event.preventDefault();
        $('#notIncludeAlert').remove();
        setDP2Selected(event.target.value);
        props.DP2(encodeURIComponent(event.target.value));
        if((event.target.value!=="" && sonValue!=="" && fatherValue!=="") || (event.target.value==="" && sonValue==="" && fatherValue!=="") )
            setBtnDisabled(false);
        else
            setBtnDisabled(true);
    }

	return (
        <div className="d-flex flex-column singleCriteriaContainer">
            <div className="loading">
                <p>Loading Data. Please wait...</p>
            </div>
            <div className="singleCriteria d-flex">
                <div className="d-flex flex-column filter">
                    <span className="mb-1">Data Point #1</span>
                    <select id="father" onChange={handleChangeFather}>
                        <option key = "" value="selectOption" >select</option>
                        {options.PromiseResult ? options.PromiseResult.map((data)=>(
                            <option key = {data.value} value = {data.value} >{data.value}</option>
                        )) : <option value="loading">Loading...</option>}
                    </select>
                </div>
                <span className="p-2 mb-4 material-symbols-outlined directional_arrow">keyboard_arrow_right</span>
                <div className="d-flex flex-column filter">
                    <span className="mb-1">Value</span>
                    <select id="son" onChange={handleChangeSon}>
                        <option key = "" value = "selectOption" >select</option>
                        {optionsSon.PromiseResult ? optionsSon.PromiseResult.map((data)=>(
                            <option key = {data.value} value = {data.value} >{data.value}</option>
                        )) : <option value="loading">Loading...</option>}
                    </select>
                </div>
                <div className="d-inline p-2 mb-4">vs</div>
                <div className="d-flex flex-column filter">
                    <span className="mb-1">Data Point #2</span>
                    <select id="notInclude" onChange={handleChangeNotInclude}>
                        <option key = "" value = "selectOption" >select</option>
                        {optionsNotInclude.PromiseResult ? optionsNotInclude.PromiseResult.map((data)=>(
                            <option key = {data.value} value = {data.value} >{data.value}</option>
                        )) : <option value="loading">Loading...</option>}
                    </select>
                </div>
                <div className="d-inline mb-4">
                    <button id="submit" className="btn btn_global btn-get-data criteria_submit" onClick={()=>props.handleClick()} disabled={btnDisabled}>Show</button>
                </div>
                <Export IsSvgRendered={props.IsSvgRendered} setImgFormat={setImgFormat} IsImgFormat={imgFormat}/>
            </div>
            <Sort/>
        </div>
	);
};

export default InputArea;