import * as d3 from 'd3';
import '../Sort/Sort.css';

export default function Sort() {  
    

      return (  
        
        <div className='filter flex-start'>
            <span className="mb-1">Sort</span>
            <select id="sort">
                <option value="VH2L" defaultValue>Value Highest to Lowest</option>
                <option value="VL2H">Value Lowest to Highest</option>
                {/* <option value="L">Logical</option>
                <option value="LR">Logical Reverse</option> */}
            </select>
        </div>
      )  
}  