import $ from "jquery";
import _ from "lodash";
import * as d3 from 'd3';
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let queryData = "";

const Utils = {
    pivot:[],
    across:[],
    // restrictedColumns:["Column1", "Research ID", "FIPS Code", "Age"],
    restrictedColumns:["id"],
    GetGooJson: function(){
        if (!queryData) {
            return new Promise((resolve, reject) => {
              signInAnonymously(auth)
                .then(() => {
                    const db = getDatabase(app);
                    const query = ref(db);

                    const promise = new Promise((resolve, reject) => {
                        onValue(query, (snapshot) => {
                            const data = snapshot.val();
                            resolve(data);
                        });
                    });

                    return promise.then((data) => {
                        const goodJson = JSON.parse(JSON.stringify(data).replace(/"\s+|\s+"/g,'"'));
                        queryData = goodJson;
                        resolve(queryData);
                    });
                })
                .catch((error) => {
                  console.error("Error signing in anonymously:", error);
                  reject(error);
                });
            });
          }
        
          return Promise.resolve(queryData);
      },
    GetGeneralDataByKey: function(stringKey){
        return new Promise((resolve) => {
            Utils.GetGeneralJsonDataByKey(stringKey).then(generalDataByKey => {
                let groupedDataByKey = _.groupBy(generalDataByKey, x=>x.name);
                let keys = Utils.GetKeys(groupedDataByKey);
                
                resolve(Utils.CreateDataByKey(keys, groupedDataByKey));
            });
        });
    },
    GetGeneralJsonDataByKey: function(stringKey){
        let data = [];
        return new Promise((resolve, reject) => {
            Utils.GetGooJson().then(dataJson => {
                for (var i = 0; i < dataJson.length; i++)
                {
                    if( dataJson[i][stringKey] !== undefined)
                    {
                        var obj = {name: dataJson[i][stringKey] };
                        data.push(obj);
                    }
                }
              resolve(data);
            });
        });
    },
    GetKeys: function(data){
        const arrayKeys = [];
        var keys = Object.keys(data);   
        $.each(keys, function(i, e) {
            if ($.inArray(e, arrayKeys) == -1) arrayKeys.push(e);
        });
    
        return  arrayKeys;
    },
    CreateDataByKey: function(keys, groupedData){
        let data = [];
        for (var i = 0; i < keys.length; i++){
            let objectModel = {name: keys[i], value: groupedData[keys[i]].length};
            data.push(objectModel);
        }

        return data;
    },
    GetGeneralJsonDataByMultipleKeys: function(stringKeyOne, stringKeyTwo, stringKeyThree){
        let data = [];
        return new Promise((resolve, reject) => {
            Utils.GetGooJson().then(dataJson => {
                for (var i = 0; i < dataJson.length; i++)
                {
                    if( dataJson[i][stringKeyOne] !== undefined  && dataJson[i][stringKeyThree] !== undefined && dataJson[i][stringKeyOne] === stringKeyTwo && dataJson[i][stringKeyThree])
                    {
                        var obj = { name: dataJson[i][stringKeyThree] };
                        data.push(obj);
                    }
                }
                resolve(data);
            });
        });
    },
    GetGeneralDataByMultipleKeys: function(stringKeyOne, stringKeyTwo, stringKeyThree){
        return new Promise ((resolve) => {
            Utils.GetGeneralJsonDataByMultipleKeys(stringKeyOne, stringKeyTwo, stringKeyThree).then(generalDataByMultipleKeys => {
                let groupedDataByKey = _.groupBy(generalDataByMultipleKeys, x=>x.name);
                let keys = Utils.GetKeys(groupedDataByKey);

                resolve(Utils.CreateDataByKey(keys, groupedDataByKey));
            })
        });
    },
    GetMainOptionList: function(){
        const arrayKeys = [];
        return new Promise((resolve, reject) => {
          Utils.GetGooJson().then(dataJson => {
            for (var i = 0; i < dataJson.length; i++)
            {            
                var keys = Object.keys(dataJson[i]);   
                $.each(keys, function(i, e) {
                    if ($.inArray(e, arrayKeys) == -1 && Utils.restrictedColumns.includes(String(e)) == false) arrayKeys.push(e);
                });
            }
            resolve(_.sortBy(arrayKeys, item => item.toLowerCase()));
          });
        });
    },
    GetFatherOptions: function(NotInclude){
        return new Promise((resolve, reject) => {
            Utils.GetMainOptionList().then(arrayKeys => {
                const options = [];
                $.each(arrayKeys, function(i, e) {
                    if(e != NotInclude)
                        options.push({ value: e , label: e});
                });
              resolve(options);
            });
        });
    },
    GetSonOptions: function(stringKey){
        const sonOptions = [];
        return new Promise((resolve) => {
            Utils.GetGeneralJsonDataByKey(stringKey).then(generalDataByKey => {
                let groupedDataByKey = _.groupBy(generalDataByKey, x=>x.name);
                let keys = Utils.GetKeys(groupedDataByKey);

                $.each(keys, function(i, e) {
                    if(e){
                        sonOptions.push({ value: e , label: e});
                    }
                });

                resolve(_.sortBy(sonOptions, item=>item.value));
            })
        });
    },
    //new collection of data
    GetComposeData: function(stringKeyOne, stringKeyTwo){
        return new Promise((resolve) => {
            Utils.GetData(stringKeyOne, stringKeyTwo).then(data => {
                let dataOne = [];
                let dataTwo = [];
                
                for (var i = 0; i < data.length; i++)
                {
                    if ($.inArray(data[i].value, dataTwo) == -1) dataTwo.push(data[i].value);

                }
                
                var output = data.reduce((result, item) => {
                    var first = result[item.name] = result[item.name] || {};
                    var second = first[item.value] = first[item.value] || [];
                    second.push(item);
                    return result;
                }, {});
                
                var keys = Object.keys(output); 
                
                for (var i = 0; i < keys.length; i++){
                    let objectData = {name: keys[i]};
                    $.each(dataTwo, function(i, e) {
                        objectData[e]=0;
                    });
                    dataOne.push(objectData);
                }

                
                for (var i = 0; i < dataOne.length; i++){
                    for (var j = 0; j < dataTwo.length; j++){
                        if(output[dataOne[i].name][dataTwo[j]]!== undefined ){
                            dataOne[i][dataTwo[j]] = output[dataOne[i].name][dataTwo[j]].length;
                        }
                    }   
                }

                let dataPivot = dataTwo.flatMap(age => dataOne.map(d => ({state: d.name, age, population: d[age]})));
                Utils.pivot = dataPivot;
                Utils.across = dataTwo;
                resolve(data);
            })
        })
    },
    GetData: function(stringKeyOne, stringKeyTwo){
        return new Promise(function(resolve, reject){

            Utils.GetJsonData(stringKeyOne, stringKeyTwo).then(function(jsonData){
                resolve(jsonData);
            }).catch(function(error){
                reject(error);
            });
        });
    },    
    GetJsonData: function(stringKeyOne, stringKeyTwo){
        let data = [];
        return new Promise((resolve, reject) => {
            Utils.GetGooJson().then(dataJson => {
                for (var i = 0; i < dataJson.length; i++)
                {
                    if( dataJson[i][stringKeyOne] !== undefined && dataJson[i][stringKeyTwo] !== undefined && dataJson[i][stringKeyOne] && dataJson[i][stringKeyTwo])
                    {
                        var obj = {
                            name: dataJson[i][stringKeyOne],
                            value: dataJson[i][stringKeyTwo]
                        };
                        data.push(obj);
                    }
                }
              resolve(data);
            });
        });
    },
    GetTotalPopulationBySingleCriteria: function(data){
        let total =0;
        $.each(data, function(i, e) {
            if(e.value!==undefined)
                total = total+e.value;        
            
        });

        return total;
    },
    GetTotalPopulationByDoubleCriteria: function(data){
        let total =0;
        $.each(data, function(i, e) {
            if(e.population!==undefined)
                total = total+e.population;        
            
        });
        return total;
    },
    GetZoomableData: function(){
        
        let allKeys = Utils.GetMainOptionList();
        let zoomable = {
            name: "Population",
            children : []
        }

        let mainKeys = $.grep(allKeys, function(value) {
            return (Utils.restrictedColumns.includes(String(value)) == false);
          });

        
        $.each(mainKeys, function(i, e) {
            
            let generalDataByKey = Utils.GetGeneralJsonDataByKey(e);
            let groupedDataByKey = _.groupBy(generalDataByKey, x=>x.name);
            let subKeys = Utils.GetKeys(groupedDataByKey);
            let son = {
                name: e,
                children: []
            };

            $.each(subKeys, function(id, el) { 
                
                let grandSon = {
                    name: el,
                    size: groupedDataByKey[el].length
                }
                son.children.push(grandSon);
            });

            zoomable.children.push(son);
            
        });
        
        return zoomable;
    },
    selectSort(data, isDataNameValue){
        if($("#sort") != null && !isDataNameValue){
          switch($("#sort").val()){
              case 'VH2L':
                  return d3.groupSort(data, D => d3.sum(D, d => -d.population), d => d.state).slice(0, 20);
              case 'VL2H':
                  return d3.groupSort(data, D => d3.sum(D, d => -d.population), d => d.state).slice(0, 20).reverse();
            //   case 'L':
            //       return d3.groupSort(data, D => d3.sum(D, d => -d.age), d => d.state).slice(0, 20);
            //   case 'LR':
            //       return d3.groupSort(data, D => d3.sum(D, d => -d.age), d => d.state).slice(0, 20).reverse();
              default:
                  return d3.groupSort(data, D => d3.sum(D, d => -d.population), d => d.state).slice(0, 20);
            }
        }
        else if($("#sort") != null && isDataNameValue){
            switch($("#sort").val()){
                case 'VH2L':
                    return d3.groupSort(data, ([d]) => -d.value, d => d.name);
                case 'VL2H':
                    return d3.groupSort(data, ([d]) => -d.value, d => d.name).reverse();
                // case 'L':
                //     return d3.groupSort(data, ([d]) => d.name, d => d.name);
                // case 'LR':
                //     return d3.groupSort(data, ([d]) => d.name, d => d.name).reverse();
                default:
                    return d3.groupSort(data, ([d]) => -d.value, d => d.name);
            }
        }
    },
    GetCustomZoomableData: function(data){

        if (data.length !== 0) {
            let zoomable = {
              name: "Population",
              total: 0,
              children: []
            }
          
            $.each(data, function(i) {
              let child = {
                name: data[i].state,
                children: []
              }
          
              let childNames = zoomable.children.map(function(child) {
                return child.name;
              });
          
              if (childNames.indexOf(child.name) === -1) {
                zoomable.children.push(child);
              }
            })
          
            let totalSize = 0; // Initialize the variable to track the sum of sizes
          
            $.each(data, function(k) {
              $.each(zoomable.children, function(j) {
                if (zoomable.children[j].name === data[k].state) {
                  let grandChild = {
                    name: data[k].age,
                    size: data[k].population
                  };
                  zoomable.children[j].children.push(grandChild);
          
                  totalSize += data[k].population; // Accumulate the size value
                }
              })
            })
          
            zoomable.total = totalSize; // Add the total size attribute to the zoomable object
          
            return zoomable;
          }
    },
}

export default Utils;