import React from "react";
import jsPDF from "jspdf";

const ExportHelpers = {
    GenerateImageLink: async function(imgFormat, FileName){
        const logoURL = "/images/Logo.png";
        const svg = document.getElementById('chart');
        const svgAsXML = (new XMLSerializer()).serializeToString(svg);
        const svgData = 'data:image/svg+xml,' + encodeURIComponent(svgAsXML);

        if(imgFormat.toLowerCase() == 'default'){
            return;
        }

        if(imgFormat.toLowerCase() == "svg"){
            return svgData;
        }
        const img = await ExportHelpers.LoadImage(svgData);

        const file = await fetch(process.env.PUBLIC_URL + logoURL)
                .then(response => response.blob())
                .then(imageBlob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(imageBlob);
                    return new Promise (resolve => {
                        reader.onload = async () => {
                            const logoImg = await ExportHelpers.LoadImage(reader.result);
                            resolve(ExportHelpers.CreateCanvas(svg, img, logoImg, imgFormat));
                        }
                    })
                });

        if(imgFormat.toLowerCase() == "pdf"){
            await ExportHelpers.GeneratePDF(file, imgFormat, FileName);
            return;
        }
        return file;
    },
    LoadImage: async function(url){
        const img = document.createElement('img')
        img.src = url
        return new Promise((resolve, reject) => {
          img.onload = () => resolve(img)
          img.onerror = reject
        })
    },
    CreateCanvas: function(svg, img, logoImg, imgFormat){
        let logoYPosition = parseInt(svg.clientHeight) + parseInt(40);

        const canvas = document.createElement('canvas');
        // canvas.width = parseInt(svg.clientWidth) + parseInt(40);
        // canvas.height = parseInt(svg.clientHeight) + parseInt(120);
        canvas.width = 1000;
        canvas.height = 1000;
        let ctx = canvas.getContext('2d');
        ctx.fillStyle="white";
        ctx.fillRect(0,0,canvas.width,canvas.height);
        console.log(svg.clientWidth);
        ctx.drawImage(img, 20, 20, svg.clientWidth, svg.clientHeight);
        ctx.drawImage(logoImg, 20, logoYPosition, 213, 60);
        
        const dataURL = canvas.toDataURL('image/' + imgFormat, 1);

        return dataURL;
    },
    GeneratePDF: async function(img, imgFormat, FileName){
        const doc = new jsPDF("l", "in", "a4");
        doc.addImage(img, imgFormat, 0.25, 0.25, 9, 9);
        doc.save(FileName + ".pdf");
    },
    GenerateFileName: function(DP1, DP1Val, DP2){
        if(DP1Val){
            return DP1 + "_" + DP1Val + "_" + DP2;
        } else if(!DP2){
            return DP1;
        } else {
            return DP1 + "_" + DP2;
        }
    }
}

export default ExportHelpers;