import React, { useRef, useEffect } from "react";
import { Runtime, Inspector } from "@observablehq/runtime";
import notebook1 from "@ujwalamusku/socio-economic-status-distribution-of-artists-in-the-usa";
import notebook2 from "@a8fbc50a77f5a8b4/art-category-2018-2020-pyramid-chart";
import notebook3 from "@a8fbc50a77f5a8b4/sequences-sunburst";
import notebook4 from "@ujwalamusku/bar-chart-race-artists";
import notebook5 from "@ujwalamusku/demographics-of-artists-in-the-usa";
import notebook6 from "@d/da4236bc38139973";
import notebook7 from "@d/2492f29b6eacfcbe";
import notebook8 from "@radhikaagr03/ahs_stackedbarchart";
import notebook9 from "@manohar2022/acpsa_value_job_compensation";
import notebook10 from "@d/7bfb7dee604135ee";
import notebook11 from "@d/a52fd26228bf0d91";

const notebooks = {
  "1": notebook1,
  "2": notebook2,
  "3": notebook3,
  "4": notebook4,
  "5": notebook5,
  "6": notebook6,
  "7": notebook7,
  "8": notebook8,
  "9": notebook9,
  "10": notebook10,
  "11": notebook11
};

function SecondPage(id) {
  const ref = useRef();

  useEffect(() => {
    const runtime = new Runtime();

    const notebookImport = notebooks[id.id];
    if (!notebookImport) {
      console.log("No card matches the given ID");
      return;
    }

    runtime.module(notebookImport, Inspector.into(ref.current));

    return () => runtime.dispose();
  }, [id.id]);

  return (
    <>
      <div ref={ref} />
    </>
  );
}

export default SecondPage;