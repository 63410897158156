import { select } from "d3";
import * as d3 from 'd3';

export default function CreateBarChart(svgRef, data, {
    x = (d, i) => i,
    y = d => d,
    title,
    legendWidth = 220,
    marginTop = 20,
    marginRight = 0,
    marginBottom = 30,
    marginLeft = 60,
    width = 640,
    height = 400,
    xDomain,
    xRange = [60, width - legendWidth - marginLeft],
    yType = d3.scaleLinear,
    yDomain,
    yRange = [height - marginBottom, marginTop],
    xPadding = 0.1,
    yFormat,
    yLabel,
    colors = d3.schemeTableau10,
    totalPopulation,
} = {}) {
    const X = d3.map(data, x);
    const Y = d3.map(data, y);

    if (xDomain === undefined) xDomain = X;
    if (yDomain === undefined) yDomain = [0, d3.max(Y)];
    xDomain = new d3.InternSet(xDomain);

    const I = d3.range(X.length).filter(i => xDomain.has(X[i]));

    if (colors === undefined) {
        colors = d3.quantize(t => d3.interpolateSpectral(t), I.length);
    } else if (colors.length < I.length) {
        const colorInterpolator = d3.interpolateSpectral;
        colors = Array.from({ length: I.length }, (_, i) => colorInterpolator(i / (I.length - 1)));
    }

    const color = d3.scaleOrdinal(X, colors);

    // Legend
    const legendItemHeight = 16;
    const legendMargin = 10;
    const legendHeight = I.length * legendItemHeight + legendMargin;

    // Calculate the total width of the chart and the legend
    const totalWidth = width + legendWidth + marginLeft;

    // Adjust xScale range to keep the bars within the visible area
    const xScale = d3.scaleBand(xDomain, [0, width - legendWidth]).padding(xPadding);
    const yScale = yType(yDomain, yRange);
    const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
    const yAxis = d3.axisLeft(yScale).ticks(height / 40).tickFormat(d => `${((d / totalPopulation) * 100).toFixed(2)}%`);

    const svg = select(svgRef.current)
        .attr("width", totalWidth)
        .attr("height", Math.max(height, legendHeight))
        .attr("viewBox", [0, 0, totalWidth, Math.max(height, legendHeight)])
        .attr("style", "max-width: 100%; height: auto;");

    const container = svg.append("g")
        .attr("transform", `translate(${marginLeft + 10}, 0)`)
        .attr("class", "barChart");

    const bar = container.append("g")
        .attr("class", "bars")
        .selectAll("rect")
        .data(I)
        .join("rect")
        .attr("x", i => xScale(X[i]))
        .attr("y", i => yScale(Y[i]))
        .attr("height", i => yScale(0) - yScale(Y[i]))
        .attr("width", xScale.bandwidth())
        .attr("fill", (d, i) => color(i));

    if (title) bar.append("title")
        .text(title);

    const legend = svg.append("g")
        .attr("class", "legend")
        .attr("transform", `translate(${width + marginLeft + 20}, 0)`);

    // Add a white background to the legend
    legend.append("rect")
        .attr("width", legendWidth) // Set an appropriate width to cover the legend items
        .attr("height", I.length * legendItemHeight) // Adjust the height based on the number of legend items
        .attr("fill", "white");

    const legendItems = legend.selectAll("g")
        .data(I)
        .join("g")
        .attr("transform", (d, i) => `translate(0, ${i * legendItemHeight})`)
        .sort((a, b) => d3.ascending(a - b));

    legendItems.append("rect")
        .attr("width", 14)
        .attr("height", 14)
        .attr("fill", (d, i) => color(i));

    legendItems.append("text")
        .attr("x", 24)
        .attr("y", 6.5)
        .attr("style", "font-size: .7rem")
        .attr("dy", "0.35em")
        .text(d => X[d]);

    const yGroup = container.append("g")
        .attr("class", "y-axis")
        .call(yAxis)
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").clone()
            .attr("x2", width - legendWidth)
            .attr("stroke-opacity", 0.1))
        .call(g => g.append("text")
            .attr("x", -marginLeft)
            .attr("y", 10)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text(yLabel));

    svg.call(d3.zoom()
        .scaleExtent([1, 8])
        .translateExtent([[0, 0], [totalWidth, height]])
        .extent([[0, 0], [totalWidth, height]])
        .on("zoom", zoomed));

    function zoomed(event) {
        const t = event.transform;
        const updatedXScale = xScale.copy().range([0, width * t.k - legendWidth]).paddingInner(xPadding);
        const updatedYScale = yScale.copy().range([height - marginBottom, marginTop]).domain([0, yScale.domain()[1] / t.k]).nice();

        const minBarSize = 1 / t.k;

        svg.select(".bars").selectAll("rect")
            .attr("x", (d, i) => updatedXScale(X[i]) + t.x)
            .attr("y", i => updatedYScale(Y[i]))
            .attr("height", (d, i) => Math.max(updatedYScale(0) - updatedYScale(Y[i]), minBarSize))
            .attr("width", updatedXScale.bandwidth());

        svg.select(".y-axis")
            .call(d3.axisLeft(updatedYScale).ticks(height / 40).tickFormat(d => `${((d / totalPopulation) * 100).toFixed(2)}%`));

        svg.selectAll(".grayTickLine").remove();

        svg.selectAll(".tick")
            .append("line")
            .attr("class", "grayTickLine")
            .attr("stroke", "currentColor")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", width - legendWidth)
            .attr("y2", 0)
            .attr("stroke-opacity", 0.1);
    }

    return svg.node();
}
