import MenuItems from "./MenuItems";

const closeMobile = () => {
    let element = document.getElementById("hamburger_menu_icon");
    element.classList.remove("open");
}

const Dropdown = ({
    submenus,
    dropdown,
    depthLevel
}) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
    return ( 
        <ul className = {`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}> 
            {
                submenus.map((submenu, index) => ( <MenuItems item = {submenu} key = {index} depthLevel = {depthLevel} onClick={closeMobile}/>))
            } 
        </ul>
    );
};

export default Dropdown;