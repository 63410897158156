import React, { useState, useEffect } from "react";
import "./Export.css";

const Export = (props) => {
    useEffect(() => {
        if(props.IsSvgRendered != false){
            document.querySelector("#exportFormat").removeAttribute("disabled");
            document.querySelector("#exportSubmit").removeAttribute("disabled");
        } else {
            document.querySelector("#exportFormat").setAttribute("disabled", "disabled");
            document.querySelector("#exportSubmit").setAttribute("disabled", "disabled");
        }
    }, [props.IsSvgRendered]);

    const exportFormat = () => {
        if(props.IsSvgRendered && document.querySelector("#exportFormat").value.toUpperCase() != "DEFAULT"){ 
            switch(props.IsImgFormat){
                case true:
                    props.setImgFormat(false);
                    break;
                case false:
                    props.setImgFormat(true);
                    break;
            }
        }
    }
    

    return (
        <div className="d-flex mb-4 exportParent">
            <div>
                <select id="exportFormat" defaultValue={'DEFAULT'}>
                    <option value="DEFAULT" disabled>Export Format..</option>
                    <option>SVG</option>
                    <option>PNG</option>
                    <option>JPEG</option>
                    <option>PDF</option>
                </select>
            </div>
            <div>
                <button id="exportSubmit" className="btn btn_global btn-get-data criteria_submit" onClick={exportFormat}>Export</button>
            </div>
        </div>
    );
};

export default Export;
