import { select } from "d3";
import * as d3 from 'd3'

function parseLargeValue(value) {
    return parseFloat(value.replace(/[$,]/g, ""));
  }

export default function CreatePieChart(svgRef, data, {
    name = ([x]) => x,
    value = ([, y]) => y,
    title,
    width = 640,
    height = 400,
    innerRadius = 0,
    outerRadius = Math.min(width, height) / 2,
    labelRadius = (innerRadius * 0.2 + outerRadius * 0.8),
    format = ",",
    names,
    colors,
    stroke = innerRadius > 0 ? "none" : "white",
    strokeWidth = 1,
    strokeLinejoin = "round",
    padAngle = stroke === "none" ? 1 / outerRadius : 0,
    totalPopulation
} = {}) {
    
    const N = d3.map(data, name);
    const V = d3.map(data, value);
    const I = d3.range(N.length).filter(i => !isNaN(V[i]));

    if (names === undefined) names = N;
    names = new d3.InternSet(names);

    if (colors === undefined) colors = d3.schemeSpectral[names.size];
    if (colors === undefined) colors = d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), names.size);

    const color = d3.scaleOrdinal(names, colors);

    if (title === undefined && totalPopulation !== undefined && totalPopulation !== 0) {
        const formatValue = d3.format(format);
        title = i => `${N[i]}\n${((parseLargeValue(formatValue(V[i]))/Number(totalPopulation)) * 100).toFixed(2)}%`;
    } else {
        const O = d3.map(data, d => d);
        const T = title;
        title = i => T(O[i], i, data);
    }

    const arcs = d3.pie().padAngle(padAngle).sort(null).value(i => V[i])(I);
    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
    const arcLabel = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);

    d3.selectAll('svg').selectAll('*').remove();

    const legendItemHeight = 16;
    const legendMargin = 10;
    const legendHeight = arcs.length * legendItemHeight + legendMargin;

    const svg = select(svgRef.current)
        .attr("width", width)
        .attr("height", Math.max(height, legendHeight))
        .attr("viewBox", [-width / 2, -Math.max(height, legendHeight) / 2, width, Math.max(height, legendHeight)])
        .attr("style", "max-width: 100%; height: auto;");

    const slices = svg.append("g")
        .attr("stroke", stroke)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-linejoin", strokeLinejoin)
        .attr("transform", `translate(-200, 0)`)
        .selectAll("path")
        .data(arcs)
        .join("path")
        .attr("fill", d => color(N[d.data]))
        .attr("d", arc)
        .append("title")
        .text(d => title(d.data));

    const legend = svg.append("g")
        .attr("class", "legend")
        .attr("transform", `translate(200, ${-Math.max(height, legendHeight) / 2 + legendMargin})`)
        .selectAll("g")
        .data(arcs)
        .join("g")
        .attr("transform", (d, i) => `translate(0, ${i * legendItemHeight})`)
        .sort((a, b) => d3.ascending(N[a.data], N[b.data])); // Sort legend items

    legend.append("rect")
        .attr("width", 14)
        .attr("height", 14)
        .attr("fill", d => color(N[d.data]));

    legend.append("text")
        .attr("x", 24)
        .attr("y", 6.5)
        .attr('style', 'font-size: .7rem')
        .attr("dy", "0.35em")
        .text(d => N[d.data]);

    // const labels = svg.append("g")
    //     .attr("font-family", "sans-serif")
    //     .attr("font-size", 10)
    //     .attr("text-anchor", "middle")
    //     .attr("transform", `translate(-200, 0)`)
    //     .selectAll("text")
    //     .data(arcs)
    //     .join("text")
    //     .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
    //     .selectAll("tspan")
    //     .data(d => {
    //         const lines = `${title(d.data)}`.split(/\n/);
    //         return (d.endAngle - d.startAngle) > 0.25 ? lines : lines.slice(0, 1);
    //     })
    //     .join("tspan")
    //     .attr("x", 0)
    //     .attr("y", (_, i) => `${i * 1.1}em`)
    //     .attr("font-weight", (_, i) => i ? null : "bold")
    //     .text(d => d);
}