export const menuElements = [{
    title: "Home",
    path: "/",
},
{
    title: "Create",
    submenu: [{
            title: "Pie",
            path: "/PiePanel",
        },
        {
            title: "Bar",
            path: "/BarPanel",
        },
        {
            title: "Grouped Bar",
            path: "/GroupedBarPanel",
        },
        {
            title: "Zoomable",
            path: "/ZoomablePanel",
        },
        {
            title:"Map",
            path: "/CountyPanel"
        }
    ]
},
{
    title: "About Us",
    path: "/About"
},
{
    title: "Data",
    submenu: [{
        title: "Artists Data",
        path: "/Data",
    },
    {
        title: "Geographic Data",
        path: "/GeoData",
    }
]
}
// {
//     title: "Contact us",
//     path: "/Contact"
// }
];