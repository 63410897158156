/** @format */

import React from "react";
import "./../App.css";

const Footer = () => {
	return (
		<div className='footer'>
			<div style={{ display: "flex", alignItems: "center" }}>
				All Rights Reserved. Copyright © Arts, Entrepreneurship, and Innovation Lab
			</div>
			<div>
				<div>
					<a href='https://culturalaffairs.indiana.edu/'>
						{" "}
						Center for Cultural Affairs{" "}
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
