import { select } from "d3";
import * as d3 from 'd3'

export default function CreatedGroupedBarChart(svgRef, data, options) {
  let {
    x = (d, i) => i,
    y = d => d,
    z = () => 1,
    title,
    marginTop = 30,
    marginRight = 0,
    marginBottom = 30,
    marginLeft = 60,
    width = 1600,
    height = 500,
    xDomain,
    xRange = [marginLeft, width - marginRight],
    xPadding = 0.1,
    yType = d3.scaleLinear,
    yDomain,
    yRange = [height - marginBottom, marginTop],
    zDomain,
    zPadding = 0.05,
    yFormat,
    yLabel,
    colors = d3.schemeTableau10,
    totalPopulation,
    legendWidth = 220,
    legendItemHeight = 12,
    legendRectSize = 9,
    legendSpacing = 7,
    legendMargin = 10,
  } = options || {};

  const X = d3.map(data, x);
  const Y = d3.map(data, y);
  const Z = d3.map(data, z);

  if (xDomain === undefined) xDomain = X;
  if (yDomain === undefined) yDomain = [0, d3.max(Y)];
  if (zDomain === undefined) zDomain = Z;
  xDomain = new d3.InternSet(xDomain);
  zDomain = new d3.InternSet(zDomain);

  const I = d3.range(X.length).filter(i => xDomain.has(X[i]) && zDomain.has(Z[i]));

  const xScale = d3.scaleBand(xDomain, xRange).paddingInner(xPadding);
  const xzScale = d3.scaleBand(zDomain, [0, xScale.bandwidth()]).padding(zPadding);
  const yScale = yType(yDomain, yRange);
  const zScale = d3.scaleOrdinal(zDomain, colors);
  const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
  const yAxis = d3.axisLeft(yScale).ticks(height / 60).tickFormat(d => `${((d/totalPopulation) * 100).toFixed(2)}%`);

  if (title === undefined && totalPopulation !== undefined && totalPopulation !== 0) {
    const formatValue = yScale.tickFormat(100, yFormat);
    title = i => `${X[i]}\n${Z[i]}\n${((formatValue(Y[i])/totalPopulation) * 100).toFixed(2)}%`;
  } else {
    const O = d3.map(data, d => d);
    const T = title;
    title = i => T(O[i], i, data);
  }

  const newMarginLeft = marginLeft + 10;
  const totalWidth = width + legendWidth + marginLeft; // Calculate totalWidth including the legend

  // Calculate the total height required for the legend
  const legendHeight = I.length * legendItemHeight + legendMargin;

  const svg = select(svgRef.current)
    .attr("width", totalWidth) // Set the SVG width to totalWidth
    .attr("height", Math.max(height, legendHeight))
    .attr("viewBox", [0, 0, totalWidth, Math.max(height, legendHeight)])
    .attr("style", "max-width: 100%; height: auto;");

  svg.append("g")
    .attr("class", "y-axis")
    .attr("transform", `translate(${newMarginLeft},0)`)
    .call(yAxis)
    .call(g => g.select(".domain").remove())
    .call(g => g.selectAll(".tick line").clone()
      .attr("x2", width - newMarginLeft - marginRight)
      .attr("stroke-opacity", 0.1))
    .call(g => g.append("text")
      .attr("x", -newMarginLeft)
      .attr("y", 10)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .text(yLabel));

  const bar = svg.append("g")
    .attr("class", "bar")
    .selectAll("rect")
    .data(I)
    .join("rect")
    .attr("x", i => xScale(X[i]) + xzScale(Z[i]))
    .attr("y", i => yScale(Y[i]))
    .attr("width", xzScale.bandwidth())
    .attr("height", i => yScale(0) - yScale(Y[i]))
    .attr("fill", i => zScale(Z[i]));

  if (title) {
    bar.append("title")
      .text(title);
  }

  // ... Add the legend ...

  const legendGroup = svg.append("g")
    .attr("transform", `translate(${width + marginLeft}, ${marginTop})`);

  legendGroup.append("rect")
    .attr("width", legendWidth)
    .attr("height", I.length * legendItemHeight + legendMargin)
    .attr("fill", "white");

  const legendItems = legendGroup.selectAll('.legend-item')
    .data(zDomain)
    .enter()
    .append('g')
    .attr('class', 'legend-item')
    .attr('transform', (d, i) => `translate(0, ${i * legendItemHeight})`);

  legendItems.append('rect')
    .attr('width', legendRectSize)
    .attr('height', legendRectSize)
    .attr('fill', d => zScale(d));

  legendItems.append('text')
    .attr('x', legendRectSize + legendSpacing)
    .attr('y', legendRectSize / 2)
    .attr('dy', '0.35em')
    .style("font-size", ".7rem")
    .text(d => d);

  // ... End of legend addition ...

  svg.call(d3.zoom()
    .scaleExtent([0, 50])
    .on("zoom", zoomed));

    function zoomed(event) {
      const t = event.transform;
    
      // Calculate the updated xScale with the event transform scale (k) and translate (x)
      const updatedXScale = xScale.copy().range([0, width * t.k]).paddingInner(xPadding);
    
      // Calculate the updated yScale with the event transform scale (k) and translate (y)
      const updatedYScale = yScale.copy().range([height - marginBottom, marginTop]).domain([0, yScale.domain()[1] / t.k]).nice();
    
      // Calculate the minimum height or width for the bars based on the zoom scale (k)
      const minBarSize = 1 / t.k;
    
      // Update the x and y attributes of the bars using the updated xScale, yScale, and event transform parameters
      svg.select(".bar").selectAll("rect")
        .attr("x", (d, i) => updatedXScale(X[i]) + t.x + xzScale(Z[i]) * t.k)
        .attr("y", (d, i) => updatedYScale(Y[i]))
        .attr("width", xzScale.bandwidth() * t.k)
        .attr("height", (d, i) => Math.max(updatedYScale(0) - updatedYScale(Y[i]), minBarSize));
    
      // Update the transform attribute of the x-axis using the event transform translate (x)
      svg.select(".x-axis")
        .attr("transform", "translate(" + t.x + "," + (height - marginBottom) + ")")
        .call(xAxis.scale(updatedXScale));
    
      // Update the y-axis without modifying the transform
      svg.select(".y-axis")
        .call(yAxis.scale(updatedYScale).tickValues(updatedYScale.ticks())); // Remove tick format logic
    
      // Remove existing tick lines
      svg.selectAll(".grayTickLine").remove();
    
      // Add new tick lines
      svg.select(".y-axis")
        .selectAll(".tick")
        .append("line")
        .attr("class", "grayTickLine")
        .attr("stroke", "currentColor")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", width - marginLeft - marginRight)
        .attr("y2", 0)
        .attr("stroke-opacity", 0.1);
    }
    
    
}
