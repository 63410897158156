import React from "react";
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon } from "react-share";

export default function SocialShareButtons({url}){
      const styles = {
        marginRight: '5px',
        marginLeft: '5px'
      };

      return (
        <>
          <FacebookShareButton
            url={url}
            style={styles}
          >
            <FacebookIcon size={36} round/>
          </FacebookShareButton>
          <TwitterShareButton
          url={url}
          style={styles}
          >
            <TwitterIcon size={36} round/>
          </TwitterShareButton>
          <LinkedinShareButton
          url={url}
          style={styles}
          title="Arts Analytics"
          summary="Arts Analytics"
          >
            <LinkedinIcon size={36} round/>
          </LinkedinShareButton>
        </>
      );
}