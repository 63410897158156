import React, { useRef, useEffect, useState} from "react";
import CreatePieChart from "./Pie";
import Utils from "../../helpers/Data";


function PieChart(props) {  

    const svgRef = useRef();
    const[total, setTotal] = useState(0);

    useEffect(()=>{
        if(total > 0){
            props.IsSvgRendered(true);
        }
    }, [total])

    useEffect(()=>{
        if(props.data.length != 0){
            Promise.all([props.data]).then(data => {
                CreatePieChart(svgRef, data[0], {
                    name: d => d.name,
                    value: d => d.value,
                    width: 1100,
                    height: 700,
                    totalPopulation: Utils.GetTotalPopulationBySingleCriteria(data[0])
                });  
                setTotal(Utils.GetTotalPopulationBySingleCriteria(data[0]));
            })
        }
    });

      return (  
        <>
            <div>
                {total > 0 ? <div>Total Population: {total}</div> : <div></div>}
                {total > 0 ? <svg ref={svgRef} id="chart"></svg> : <div></div>}
            </div>
        </>
            )  
}  

export default PieChart;
