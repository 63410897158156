import React, { useRef, useEffect, useState} from "react";
import CreateBarChart from "./Bar";
import * as d3 from 'd3';
import Utils from "../../helpers/Data";

function BarChart(props) {  

    const svgRef = useRef();    
    const[total, setTotal] = useState(0);

    useEffect(()=>{
        if(total > 0){
            props.IsSvgRendered(true);
        }
    }, [total])

    useEffect(()=>{
        if(props.data.length!==0){
            d3.selectAll('svg#chart').selectAll('*').remove();
            Promise.all([props.data]).then(data => {
                let barChart = document.querySelector(".barChart");

                if(!barChart){
                    CreateBarChart(svgRef, data[0], {
                        x: d => d.name,
                        y: d => d.value,
                        xDomain: Utils.selectSort(data[0], true),
                        yLabel: "Population",
                        width: 720,
                        height: 700,
                        totalPopulation: Utils.GetTotalPopulationBySingleCriteria(data[0])
                    });
                    setTotal(Utils.GetTotalPopulationBySingleCriteria(data[0]));
                }
            })
        }
        
    });

      return (  
        
        <>
            <div>
                {total > 0 ? <div>Total Population: {total}</div> : <div></div>}
                {total > 0 ? <svg ref={svgRef} id="chart"></svg> : <div></div>}
            </div>
        </>
      )  
}  

export default BarChart;
