import React from "react";

function Data() {
	return (
		<div className='panel'>
			<div className='mt-5'>
				<h1 className='heading p-3'>Creatives Rebuild New York: Guaranteed Income for Artists Data</h1>
			</div>
			<div>
                <p>The dataset for the CRNY data visualization competition is an Excel file that contains data from two sources. The first is data from the application that potential program enrollees completed to apply for the CRNY Guaranteed Income (GI) for Artists program. It includes information for all individuals who applied, regardless of whether they were ultimately accepted into the program. You can find a copy of the application form that artists completed in the Teams folder, saved as a PDF document titled “Guaranteed Income Application Questions 2022.” </p>
                <p>The second data source is the Portrait of Artists survey which CRNY administered with the goal of understanding the needs, circumstances, and experiences of artists in New York. A copy of this survey is also available in the Teams folder with the file name “Portrait of New York State Artists Survey.”</p>
                <p>All responses to the questions from the GI application form appear in the Excel file in columns with a header that begins with the prefix “g” and the corresponding question number. For instance, the header “g18_community” contains responses to question 18 on the GI application form: “How would you describe the community in which you live?” In the same way, data for responses to the Portrait of Artists survey are saved in columns with headers beginning with the prefix “p” and the number of the corresponding question from the survey.</p>
				<p>For most questions on the GI application and the Artist Portrait survey, respondents were allowed to select only one answer. In these cases, the responses for the question are all in a single column. However, some questions allowed respondents to select more than one answer. Rather than using a single column for each possible response, these responses were categorized based on the number of options a respondent selected-labeled as "two choices," "three choices," etc. For example, question 2 in the Portrait of Artists survey asks respondents, "How do you approach your practice as an artist, culture bearer, or culture maker? Select all that apply" and provides six response options from which the respondent can select one or more. In this case, the question for this information is recorded with specific categories for singular choices and "two approaches," "three approaches," through "six approaches" to record the number of options selected by the respondent.”</p>
				<p>Each respondent in the dataset is assigned a unique random identifier (“id”). “g_enrollmentstatus” and “p_status” contain information about whether each individual in the dataset was enrolled into the GI program. </p>
				<ul>
					<li><a href={process.env.PUBLIC_URL + "/files/Guaranteed Income Application Questions 2022.pdf"}>Guaranteed Income Application Questions 2022</a></li>
					<li><a href={process.env.PUBLIC_URL + "/files/Portrait of New York State Artists Survey.pdf"}>Portrait of New York State Artists Survey</a></li>
					<li><a href={process.env.PUBLIC_URL + "/files/CRNY-Surveys-Data-Library.xlsx"}>CRNY Surveys Data Library</a></li>
				</ul>
			</div>
		</div>
	);
}

export default Data;