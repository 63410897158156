/** @format */

import React from "react";
import "./Card.css";
import SecondPage from "./SecondPage";
import VisualModal from "./VisualModal";
import Data from "../visualizationInventory.js";
import { Link, BrowserRouter } from "react-router-dom";

class Card extends React.Component {
	constructor(props){
		super(props)
	}

	state = {
		show: false,
	};

	handleClick = (e) => {
		this.id = e.currentTarget.id;
	};

	showModal = () => {
		this.setState({
			show: !this.state.show,
		});
	};

	render() {
		return (
			<div>
				<div className='container my-3'>
					<div className='row row-cols-3 g-5'>
						{(this.props.data || []).map((currentElement) => {
							const {
								id,
								visualizationType,
								gif,
								heading,
								summary,
								metaData,
							} = currentElement;
							return (
								<div className='col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12' key={id}>
									<div className='visualCard h-100'>
										<div className='visualGif'>
											<img className='vis-image' src={gif} alt={id} />
										</div>
										<div className='visualHeading'>
											<h4 className='vTitle'>
												{heading}
												{/* <span className='tooltipText'>{heading}</span> */}
											</h4>
										</div>
										<div className='visualSummary'>
											<p>{summary}</p>
										</div>
										<div className='visualBtn'>
											<button
												key={id}
												id={id}
												type='button'
												className='btn btn-primary'
												style={{ display: "flex" }}
												onClick={(e) => {
													this.showModal();
													this.handleClick(e);
												}}>
												Dashboard
												<span className='material-symbols-outlined visualIcon'>
													arrow_forward
												</span>
											</button>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<VisualModal
					onClose={this.showModal}
					show={this.state.show}
					id={this.id}>
					Message in Modal
				</VisualModal>
			</div>
		);
	}
}
export default Card;
