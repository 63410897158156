/** @format */
// import "../public/images";
const Visualizations = [
	{
		id: 1,
		gif: "images/gif1.gif",
		link: "https://observablehq.com/@ujwalamusku/socio-economic-status-distribution-of-artists-in-the-usa",
		theme: "Artists",
		analysis: "Correlation",
		heading: "Socioeconomic status of artists",
		metaData: "Artists, Income, Poverty, Occupation",
		visualizationType: "Dynamic, Temporal",
		summary:
			"This visualization shows how the income and poverty relationship varies every year for each artist. This visualization represents the social and economic status of artists in the USA from 1950 to 2019. Click on the 'play' button on the top of the visualization to observe the variation of the median of the poverty level and total income of each art. The X-Axis indicates the total income (INCTOT) and Y-Axis indicates the poverty level (POVERTY). The art profession type is indicated in different colors. The area of the circles indicates the number of artists in a specific occupation.",
	},
	{
		id: 2,
		// visualizationType: "Tornado Chart",
		gif: "images/visual2.gif",
		link: "https://observablehq.com/@a8fbc50a77f5a8b4/art-category-2018-2020-pyramid-chart",
		theme: "Arts Participation",
		analysis: "Descriptive",
		heading: "U.S. Adult Arts Participation",
		metaData: "Arts Participation, Sex, Race, Ethnicity, Education",
		visualizationType: "Static, Temporal",
		summary:
			"This tornado chart compares the 2018 and 2020 participation of several  categories of artists in percentage .",
	},
	{
		id: 3,
		// visualizationType: "Sequence Sunburst",
		gif: "images/visual3.gif",
		link: "https://observablehq.com/@a8fbc50a77f5a8b4/sequences-sunburst",
		theme: "Arts Participation",
		analysis: "Descriptive",
		heading: "U.S. Adult Arts Participation",
		metaData: "Arts Participation, Sex, Employment, Children, Married, Urban",
		visualizationType: "Static, Temporal",
		summary:
			"This visualization compares the percentage of U.S. Adults participating and creating in arts activities between 2018 and 2020 .",
	},
	{
		id: 4,
		// visualizationType: "Racing Bar Graph - Temporal",
		gif: "images/gif2.gif",
		link: "https://observablehq.com/@ujwalamusku/bar-chart-race-artists",
		theme: "Artists",
		analysis: "Descriptive",
		heading: "Number of artists",
		metaData: "Artists, Occupation",
		visualizationType: "Dynamic, Temporal",
		summary:
			"This visualization shows the number of artists in the U.S. between 2005 and 2019.",
	},
	{
		id: 5,
		// visualizationType: "Sunburst Sequence",
		gif: "images/visual6.gif",
		link: "https://observablehq.com/@ujwalamusku/demographics-of-artists-in-the-usa",
		theme: "Occupation",
		analysis: "Correlations",
		heading: "Demographics of Artists in the USA",
		metaData: "Artists, Sex, Married, Race, Age, Veteran, Occupation, English-Speaking",
		visualizationType: "Static",
		summary:
			"Shows a sunburst sequence about the Number of Artists, which is futher broken down to give the percentage of gender statistics in 2nd Ring and Martitail Status, Race, Language spoken, Age Gorup and Veteran status as we go away from the center to form different rings around the Number of artists.",
	},
	{
		id: 6,
		// visualizationType: "Pie Chart",
		gif: "images/gif5.gif",
		link: "https://observablehq.com/d/da4236bc38139973",
		theme: "Occupation",
		analysis: "Correlations",
		heading: "Education of Artists",
		metaData: "Artists, Age, Education, Employment, Occupation",
		visualizationType: "Static, Temporal",
		summary:
			"This pie chart visualization depicts the artists and non-artists between 2005 to 2019. The data can be viewed by filtering the age, education, and the labor force. The age varies from under 18 to 100 years.",
	},
	{
		id: 7,
		// visualizationType: "Chloropleth Map",
		gif: "images/ARTS.gif",
		link: "https://observablehq.com/d/2492f29b6eacfcbe",
		theme: "Occupation",
		analysis: "Correlations",
		heading: "Number of Arts Establishments by County",
		metaData: "Arts Organizations, Occupation",
		visualizationType: "Static, Temporal",
		summary:
			"The chloropleth map visualization depicts the number of establishments in the arts and entertainment industry between 2009-2019 by counties in the U.S.",
	 },
	 {
		id: 8,
		// visualizationType: "Stacked Bar Chart",
		gif: "images/ahs.gif",
		link: "https://observablehq.com/@radhikaagr03/ahs_stackedbarchart",
		theme: "Occupation",
		analysis: "Correlations",
		heading: "Arts and Culture Impact on Neighborhood Choice by Ownership",
		metaData: "Arts Participation, Arts Organizations, Sex, Education, Age, Marital Status, Tenure",
		visualizationType: "Static, Temporal",
		summary:
			"This stacked bar plot illustrates how various questions related to arts and culture influence individuals' decisions in choosing a neighborhood, taking into account the distinction between rented and owned properties. The plot showcases how different factors impact these decisions within the context of neighborhood ownership types.",
	 },
	 {
		id: 9,
		// visualizationType: "Zoomable Sunburst",
		gif: "images/items.gif",
		link: "https://observablehq.com/@manohar2022/acpsa_value_job_compensation",
		theme: "Occupation",
		analysis: "Descriptive",
		heading: "Economic Significance of Arts and Culture ",
		metaData: "Arts Industries, Employment, Compensation, Jobs, Economy, Occupation",
		visualizationType: "Dynamic, Temporal",
		summary:
			"The sunburst visualization shows value added, employment created, and compensations given to employees by different arts industries from 1998 to 2021 in the U.S.",
	 },
	{
		id: 10,
		// visualizationType: "Scatter Plot",
		gif: "images/items2.gif",
		link: "https://observablehq.com/d/7bfb7dee604135ee",
		theme: "Occupation",
		analysis: "Correlations",
		heading: "Annual Trends in Kickstarter Pledges and Backers",
		// metaData: "Arts Info Test, Testing",
		// visualizationType: "Test",
		summary:
			"The scatter plot visualization shows the annual trend for different kickstarter project categories in terms of both Pledges and Number of Backers. ",
	},
	{
	   id: 11,
	   // visualizationType: "Bar Chart",
	   gif: "images/items3.gif",
	   link: "https://observablehq.com/d/a52fd26228bf0d91",
	   theme: "Consumer Expenditure",
	   analysis: "Correlations",
	   heading: "Consumer Expenditure percentage on Entertainment",
	   metaData: "Entertainment groups, Expenditure",
	   visualizationType: "Static, Temporal",
	   summary:
		   "The bar chart illustrates the trends in consumer spending percentages across distinct entertainment sectors spanning a ten-year interval from 2012 to 2021.",
	}
];
export default Visualizations;
