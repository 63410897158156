import React , { useState, useEffect }from "react";
import Utils from "../../helpers/Data";
import "./DoubleCriteria.css";
import $ from "jquery";
import Export from "../Export/Export";
import ExportUtils from "../../helpers/ExportHelper";
import Sort from "../Sort/Sort";

function InputArea(props) {

    const showSort = props.sort;
    const showExport = props.export;

    const [options, setOptions] = useState(Utils.GetFatherOptions());
    const [optionsNotInclude, setOptionsNotInclude] = useState([]);

    const [btnDisabled, setBtnDisabled] = useState(true);
    const [imgFormat, setImgFormat] = useState(false);

    const [DP1Selected, setDP1Selected] = useState('');
    const [DP2Selected, setDP2Selected] = useState('');

    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let DP1QS = "";
    let DP2QS = "";

    if(queryString){
        DP1QS = urlParams.get('DP1');
        DP2QS = urlParams.get('DP2');
    }

    function download(dataBase64URL, imageName){
        const link = document.createElement("a");
        link.href = dataBase64URL;
        link.download = imageName;
        link.click();
    }

    useEffect(() => {
        if(props.IsSvgRendered != false){
            let exportFormat = document.querySelector("#exportFormat").value;

            if(exportFormat.toLowerCase() == 'default'){
                return;
            }

            const fetchData = async () => {
                const FileName = ExportUtils.GenerateFileName(DP1Selected, "",DP2Selected);

                if(exportFormat.toLowerCase() != "pdf"){
                    const result = await ExportUtils.GenerateImageLink(exportFormat, FileName);
                    // setImgLink(result);
                    download(result, FileName + "." + exportFormat.toLowerCase());
                } else {
                    const result = await ExportUtils.GenerateImageLink(exportFormat, FileName);
                    return result;
                }
            }

            fetchData();
        }
    }, [imgFormat]);

    // useEffect(() => {
    //     if(DP1QS){
    //         querySetFather();
    //     }
    //   }, []);
    useEffect(() => {
        Utils.GetFatherOptions().then(data => {
            if($('#father').find('option[value="loading"]').length){
                $('#father').find('option[value="loading"]').remove();
                $('.loading').remove();
            }
            setOptions(data);
        });
      }, []);

      useEffect(() => {
        if(options.length){
            $.each (options, function (index, value) {
                var option = $ ('<option></option>');
                option.val(value.value);
                option.text(value.value);
                $('#father').append(option);
            });

            if(DP1QS){
                querySetFather();
            }
        }
    }, [options]);

    // useEffect(() => {
    //     if(DP1QS && DP2QS){
    //         querySetNotInclude();
    //     }
    // }, [optionsNotInclude]);

    useEffect(() => {
        if(options.length){
            $.each (optionsNotInclude, function (index, value) {
                var option = $ ('<option></option>');
                option.val(value.value);
                option.text(value.value);
                $('#notInclude').append(option);
            });

            if(DP1QS && DP2QS){
                querySetNotInclude();
            }
        }
    }, [optionsNotInclude]);

    const querySetFather = () => {
        let match = false;
        $('#fatherAlert').remove();

        $('#father option').each(function(){
            if(this.value && this.value == DP1QS){
                match = true;
            }
        })

        if(match){
            $('#father').val(DP1QS);
            setDP1Selected(DP1QS);
            props.DP1(encodeURIComponent(DP1QS));  
            // setOptionsNotInclude(Utils.GetFatherOptions(DP1QS));
            Utils.GetFatherOptions(DP1QS).then(data => {
                setOptionsNotInclude(data);
            });
        } else {
            $('#father').after('<div id="fatherAlert" style="color:red">Value for dropdown not valid.</div>');
        }
    }

    const querySetNotInclude = () => {
        let match = false;

        $('#notIncludeAlert').remove();

        $('#notInclude option').each(function(){
            if(this.value && this.value == DP2QS){
                match = true;
            }
        })

        if(match){
            $('#notInclude').val(DP2QS);
            setBtnDisabled(false);
            setDP2Selected(DP2QS);
            props.handleClick();
            props.DP2(encodeURIComponent(DP2QS));
        } else {
            $('#notInclude').after('<div id="notIncludeAlert" style="color:red">Value for dropdown not valid.</div>');
        }
    }
    
    const handleChangeFather = (event) => {
        event.preventDefault();
        $('#fatherAlert').remove();
        setDP1Selected(event.target.value);
        props.DP1(encodeURIComponent(event.target.value));  
        // setOptionsNotInclude(Utils.GetFatherOptions(event.target.value));
        Utils.GetFatherOptions(event.target.value).then(data => {
            if($('#notInclude').find('option').length){
                $('#notInclude').find('option').each(function(){
                    if($(this).val() != "selectOption"){
                        $(this).remove();
                    }
                })
            }
            setOptionsNotInclude(data);
        });
        let notIncludeValue = $('#notInclude').find(":selected").val();
        if(event.target.value!=="" && notIncludeValue !=="")
            setBtnDisabled(false);
        else
            setBtnDisabled(true);
    }

    const handleChangeNotInclude = (event) => {
        event.preventDefault(); 
        $('#notIncludeAlert').remove();
        setDP2Selected(event.target.value);
        props.DP2(encodeURIComponent(event.target.value));  
        let fatherValue = $('#father').find(":selected").val();
        if(event.target.value!=="" && fatherValue !== "")
            setBtnDisabled(false);
        else
            setBtnDisabled(true);
    }

	return (
        <div className="d-flex flex-column doubleCriteriaContainer">
            <div className="loading">
                <p>Loading Data. Please wait...</p>
            </div>
            <div className="d-flex doubleCriteria">
                <div className="d-flex flex-column filter">
                    <span className="mb-1">Data Point #1</span>
                    <select id="father" onChange={handleChangeFather}>
                        <option key = "" value = "selectOption" >select</option>
                        {options.PromiseResult ? options.PromiseResult.map((data)=>(
                            <option key = {data.value} value = {data.value} >{data.value}</option>
                        )) : <option value="loading">Loading...</option>}
                    </select>
                </div>
                <div className="d-inline p-2 mb-4">vs</div>
                <div className="d-flex flex-column filter">
                    <span className="mb-1">Data Point #2</span>
                    <select id="notInclude" onChange={handleChangeNotInclude}>
                        <option key = "" value = "selectOption" >select</option>
                        {optionsNotInclude.PromiseResult ? optionsNotInclude.PromiseResult.map((data)=>(
                            <option key = {data.value} value = {data.value} >{data.value}</option>
                        )) : <option value="loading">Loading...</option>}
                    </select>
                </div>
                <div className="d-inline mb-4">
                    <button id="submit" className="btn btn_global btn-get-data criteria_submit" onClick={()=>props.handleClick()} disabled={btnDisabled}>Show</button>
                </div>
                <Export IsSvgRendered={props.IsSvgRendered} setImgFormat={setImgFormat} IsImgFormat={imgFormat}/>
            </div>
            {showSort && <Sort/>}
        </div>
        
	);
};

export default InputArea;