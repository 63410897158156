import $ from "jquery";
import _ from "lodash";
import * as d3 from 'd3';

const CountyUtils = {
    restrictedColumns:["meanyear", "medyear", "fips", "amt_adj"],
    GetCleanData: function(data, dataPoint) {
        if(!data){
            return;
        }
        const resultArray = [];
        resultArray.push([dataPoint, "State", "County"]);
        data.forEach((obj) => {
            if (obj.hasOwnProperty('fips') && obj.hasOwnProperty(dataPoint)) {
                let fips = String(obj.fips);

                if (fips.length == 4) {
                    fips = "0" + fips;
                }

                let state = fips.substring(0, 2);
                let county = fips.substring(2);

                resultArray.push([String(obj[dataPoint]), state, county]);
            }
        });

        const resultJSON = JSON.parse(JSON.stringify(resultArray));

        return resultJSON;
    },
    GetObjectDataKeys: function(data){
        const arrayKeys = [];
        const dataJson = JSON.parse(JSON.stringify(data));
        
        for (var i = 0; i < dataJson.length; i++)
        {            
            var keys = Object.keys(dataJson[i]);   
            $.each(keys, function(i, e) {
                if ($.inArray(e, arrayKeys) == -1 && CountyUtils.restrictedColumns.includes(String(e)) == false) arrayKeys.push(e);
            });
        }

        return _.sortBy(arrayKeys, item => item.toLowerCase());
    }
}

export default CountyUtils;
