/** @format */

import React, { useState } from "react";

// FONTAWESOME IMPORTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
// REACT POPUPBOX
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import Card from "./Card";
import "./Card.css";

const Visualization = (props) => {
	const popupboxConfig = {
		titleBar: {
			enable: true,
		},
		fadeIn: true,
		fadeInSpeed: 500,
	};

	if(String(props.data) === props.data){
		return (
			<div>
				<h3 className='heading p-3'>{props.data}</h3>
			</div>
		)
	}
	
	return (
		<div>
			<Card data={props.data}/>
		</div>
	);
};

export default Visualization;
