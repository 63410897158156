/** @format */

import React from "react";
import endowment from "../endowment.png";
import "../components/About.css"

function About() {
	return (
		<div className='about panel'>
			<div className='mt-5'>
				<h1 className='heading p-3'>About Us</h1>
			</div>
			<div>
				<p>Arts Analytics was conceived of as a solution to using and interpreting data to understand the arts and cultural sector in the U.S. The project centers on data visualization as a tool for illustrating trends and statistics on topics including artists, arts funding, arts consumption, arts production, and more using publicly available data. It is a dynamic project, in that over time it will amass new data and visualizations illustrating those data, as well as updated functionalities for users to engage with the site. The intended audience for this project are people working in the policy and the arts sector, including artists and arts administrators.</p>
				<p>The project is led by the <a href="https://culturalaffairs.indiana.edu/programs/aeilab.html" target="_blank">Arts, Entrepreneurship, and Innovation Lab</a> at Indiana University. The visualizations are created by data science students at Indiana University.</p>
				<p>We are always looking for new data to visualize. If you’re an organization with data that you want to share, <a href="mailto:ccaoneil@indiana.edu">contact us</a> today.</p>
				<img src={endowment} className="about_media"/>
				<p>The Arts, Entrepreneurship, and Innovation Lab is a project of the National Endowment for the Arts in cooperation with Indiana University. This project was partially supported by a grant from the National Endowment for the Arts.</p>
				<p>The opinions expressed in materials on this website are those of the author(s) and do not represent the views of the National Endowment for the Arts Office of Research & Analysis or the National Endowment for the Arts. The Arts Endowment does not guarantee the accuracy or completeness of the information included in these materials and is not responsible for any consequences of its use. This NEA Research Lab is supported in part by an award from the National Endowment for the Arts (Award#: 1864415-38).</p>
			</div>
		</div>
	);
}

export default About;
