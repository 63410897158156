/** @format */

import React, { useState } from "react";
import "./Filter.css"
import Data from "./../visualizationInventory.js";
import Visualizations from "./../visualizationInventory.js";

const Filter = (props) => {
	const filterItems = () => {
		const metaDataValue = document.querySelector(".metaData").value;
		const vizTypeValue = document.querySelector(".vizType").value;

		let updatedList = Object.values(Data).filter((currentElement) => {
			if(metaDataValue != "default" && vizTypeValue != "default"){
				if(currentElement.metaData.includes(metaDataValue) && currentElement.visualizationType.includes(vizTypeValue)){
					return currentElement;
				}
			} else if(currentElement.metaData.includes(metaDataValue) || currentElement.visualizationType.includes(vizTypeValue)){
				return currentElement;
			}
			
			// if(currentElement.visualizationType.includes(visualization)){
			// 	return currentElement;
			// }		
		});

		if(updatedList.length <= 0){
			// updatedList = Data;
			updatedList = "No matches found."
		}

		props.homeCallback(updatedList);
		VizTypeArray();
	};

	const VizTypeArray = (keyName) => {
		if (!keyName || typeof keyName !== "string") {
		  console.error("Invalid key name. Please provide a valid string.");
		  return;
		}
	  
		const visualizationTypes = [];
	  
		for (var i = 0; i < Visualizations.length; i++) {
		  const keyValue = Visualizations[i][keyName];
	  
		  if (keyValue && typeof keyValue === "string") {
			const values = keyValue.includes(",") ? keyValue.split(",").map(type => type.trim()) : [keyValue];
	  
			values.forEach(value => {
			  if (!visualizationTypes.includes(value)) {
				visualizationTypes.push(value);
			  }
			});
		  }
		}
	  
		visualizationTypes.sort();
	  
		return visualizationTypes;
	  };

	  const vizTypeOptions = VizTypeArray("visualizationType");
	  const metaDataOptions = VizTypeArray("metaData");

	return (
			<div className="filter">
				<select className="vizType" onChange={e => filterItems()}>
					<option disabled="disabled" selected="true" value="default">Visualization Type</option>
					{vizTypeOptions.map((type, index) => (
						<option key={index}>{type}</option>
					))}
				</select>
				<select className="metaData" onChange={e => filterItems()}>
					<option disabled="disabled" selected="true" value="default">Topic</option>
					{metaDataOptions.map((type, index) => (
						<option key={index}>{type}</option>
					))}
				</select>
			</div>
	);
};

export default Filter;
