import React, { useRef, useEffect } from "react";
import CountyMap from "./CountyMap";

export default function CountyView(props) {
  const svgRef = useRef();
  if(props.data && props.countyData){
    props.IsSvgRendered(true);
  }
  return (
    <div>
      <svg ref={svgRef} id="chart"></svg>
      {props.data && props.countyData && (
        <CountyMap
          data={props.data}
          countyData={props.countyData}
          svgRef={svgRef}
        />
      )}
    </div>
  );
}
