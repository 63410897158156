// // /** @format */
// import { useState } from "react";
// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Navbar from "./components/Navbar";
// import Home from "./components/Home";
// import About from "./components/About";
// import "./components/barGraphAnimation.js";
// import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
// import Footer from "./components/Footer";
// import Contact from "./components/Contact";


// const App = () => {
// 	const [state, setState] = useState("visualBody");
	
// 	// 'visualBody' is the home page and 'visual' is visualization description pag
// 	return (
// 		<div className='container-fluid p-0 g-0 '>
// 			{/* <Router> */}
// 				<Navbar />
// 				<div className='main'>
// 					<Routes>
// 						<Route path='/' element={<Home />} />
// 						<Route path='/about' element={<About />} />
// 						<Route path='/contact' element={<Contact />} />
// 					</Routes>
// 				</div>

// 				<Footer />
// 			{/* </Router> */}
// 		</div>
// 	);
// }

// export default App;



import {  Routes, Route  } from "react-router-dom";
import "./App1.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import PiePanel from "./pages/PiePanel";
import BarPanel from "./pages/BarPanel";
import GroupedBarPanel from "./pages/GroupedBarPanel";
import ZoomablePanel from "./pages/ZoomablePanel";
import CountyPanel from './pages/CountyPanel';
import Home from "./pages/Home";
import BarRacePanel from "./pages/BarRacePanel";
import logo from "./Logo.png";
import DataPageLink from "./pages/Data";
import GeographicData from "./pages/GeoData";

const toggleOpen = () => {
	let element = document.getElementById("hamburger_menu_icon");
	element.classList.toggle("open");
}

const App = () => {
    return ( 

		<>
			<header>
				<div className="nav-area" >
					<a href="/#" className="logo" >
						<img src={logo}/>
					</a> 
					<div id="hamburger_menu_icon" onClick={toggleOpen}>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<Navbar />
				</div> 
			</header>
			<div className="panel">
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/PiePanel' element={<PiePanel />} />
					<Route path='/BarPanel' element={<BarPanel />} />
					<Route path='/GroupedBarPanel' element={<GroupedBarPanel />} />
					<Route path='/ZoomablePanel' element={<ZoomablePanel />} />
					<Route path='/BarRacePanel' element={<BarRacePanel/>} />
					<Route path='/About' element={<About/>} />
					<Route path="/Data" element={<DataPageLink/>}/>
					<Route path="/GeoData" element={<GeographicData/>}/>
					<Route path="/CountyPanel" element={<CountyPanel/>}/>
				</Routes>
			</div>
			<Footer/>
		</>
    );
};

export default App;
