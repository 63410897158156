import React, { useState, useEffect } from "react";
import $ from "jquery";
import "./SingleDropdownCriteria.css";
import Export from "../Export/Export";
import ExportUtils from "../../helpers/ExportHelper";

export default function SingleDropdownInputArea(props) {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [imgFormat, setImgFormat] = useState(false);
  const [DP1Selected, setDP1Selected] = useState("");

  let queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let DP1QS = "";

  useEffect(() => {
    if (queryString && props.countyData) {
      $("#parameterAlert").remove();
      let match = false;
      DP1QS = urlParams.get("DP1");

      $("#parameters option").each(function () {
        if (this.value && this.value === DP1QS) {
          match = true;
          setDP1Selected(DP1QS);
        }
      });

      if ($("svg#chart path").length === 0) {
        if (match) {
          $("#parameters").val(DP1QS);
          props.handleClick();
        } else {
          $("#parameters").after(
            '<div id="parameterAlert" style="color:red">Value for dropdown not valid.</div>'
          );
        }
      }
    }
  }, [queryString, DP1Selected, props.countyData, props.handleClick]);

  function download(dataBase64URL, imageName) {
    const link = document.createElement("a");
    link.href = dataBase64URL;
    link.download = imageName;
    link.click();
  }

  useEffect(() => {
    if (props.IsSvgRendered !== false) {
      let exportFormat = document.querySelector("#exportFormat").value;

      if (exportFormat.toLowerCase() === "default") {
        return;
      }

      const fetchData = async () => {
        const FileName = ExportUtils.GenerateFileName(DP1Selected);

        if (exportFormat.toLowerCase() !== "pdf") {
          const result = await ExportUtils.GenerateImageLink(
            exportFormat,
            FileName
          );
          download(result, FileName + "." + exportFormat.toLowerCase());
        } else {
          const result = await ExportUtils.GenerateImageLink(
            exportFormat,
            FileName
          );
          return result;
        }
      };

      fetchData();
    }
  }, [imgFormat, DP1Selected, props.IsSvgRendered]);

  const handleChangeParameter = (event) => {
    event.preventDefault();
    $("#parameterAlert").remove();
    setDP1Selected(event.target.value);
    props.DP1(encodeURIComponent(event.target.value));
  };

  useEffect(() => {
    setBtnDisabled(DP1Selected === "");
  }, [DP1Selected]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex doubleCriteria">
        <div className="d-flex flex-column filter">
          <span className="mb-1">Data Point #1</span>
          <select id="parameters" onChange={handleChangeParameter}>
            <option key="" value="">
              select
            </option>
            {props.options.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
        </div>
        <div className="d-inline mb-4">
          <button
            id="submit"
            className="btn btn_global btn-get-data criteria_submit"
            onClick={() => props.handleClick()}
            disabled={btnDisabled}
          >
            Show
          </button>
        </div>
        <Export
          IsSvgRendered={props.IsSvgRendered}
          setImgFormat={setImgFormat}
          IsImgFormat={imgFormat}
        />
      </div>
    </div>
  );
}
