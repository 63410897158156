import { menuElements } from "./menuElements";
import MenuItems from "./MenuItems";
import "./Navbar.css";

const Navbar = () => {
    return ( 
        <nav>
            <ul className = "menus" > {
                
                menuElements.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems item = {menu} key = {index} depthLevel = {depthLevel}/>;
                })

             } 
            </ul> 
        </nav>
    );
};

export default Navbar;