import React, { useState, useRef} from "react";
import PieChart from "../components/Pie/PieChart";
import InputArea from "../components/SingleCriteria/SingleCriteria";
import $ from "jquery";
import Utils from "../helpers/Data";
import Share from "../components/Share/Share";
import DataPageLink from "../components/DataPageLink/DataPageLink";

function PiePanel() {

    const [pieData, setPieData] = useState([]);

    const [DP1, setDP1] = useState('');
    const [DP1Value, setDP1Value] = useState('');
    const [DP2, setDP2] = useState('');
    const [svgRendered, setSvgRendered] = useState(false);
    const IsSingleCriteria = true;
    
    const handleClick = ()=>{
        
        let fatherValue = $('#father').find(":selected").val();
        let sonValue = $('#son').find(":selected").val();
        let notIncludeValue = $('#notInclude').find(":selected").val();
        if(sonValue==="")
            setPieData(Utils.GetGeneralDataByKey(fatherValue));
        else
            setPieData(Utils.GetGeneralDataByMultipleKeys(fatherValue, sonValue, notIncludeValue));
    }

    return (
        <div>
            <h1 className='heading p-3'>
                Pie Chart
            </h1>
            <div>
                <InputArea handleClick={handleClick} DP1={setDP1} DP1Value={setDP1Value} DP2={setDP2} IsSvgRendered={svgRendered}></InputArea>
            </div>
            <div>
                <PieChart data={pieData} IsSvgRendered={setSvgRendered}></PieChart>
            </div>
            <div className="shareParent">
                <Share DP1={DP1} DP1Value={DP1Value} DP2={DP2} IsSingleCriteria={IsSingleCriteria}/>
            </div>
            <DataPageLink/>
        </div>
    );

}

export default PiePanel;
