import React from "react";

function GeoData() {
	return (
		<div className='panel'>
			<div className='mt-5'>
				<h1 className='heading p-3'>Kickstarter scraped data</h1>
			</div>
			<div>
                <p>Kickstarter.com is the leading reward-based crowdfunding platform.  On Kickstarter.com, "creators" (or founders) can launch a campaign to raise funds for their project.  Then, supporters or "backers" can go to the project webpage on that site and pledge financial support for the project.  Typically, the campaign offers rewards to backers at varying levels of financial support.  All projects set a target or goal for their campaign, and only if the total funds pledged exceed this target within a pre-specified timeframe (usually 30 days) will the backers actually pay their pledged amounts and the project creators receive the funds.  In this way, hundreds of thousands of projects big and small have been launched and funded on Kickstarter, many of which are highly creative, artistic or cultural projects.</p>
                <p>The data on our website comes from two sources.  First, <a href="https://webrobots.io/kickstarter-datasets/" target="_blank">Web Robots</a> has a webscraping robot that collects information on Kickstarter projects.  We collected data on successful Kickstarter projects through 2016.  Second, we (at the <a href="https://culturalaffairs.indiana.edu/programs/aeilab/index.html" target="_blank">Arts, Entrepreneurship, and Innovation Lab</a>) mapped those Kickstarter projects to the US county level. </p>
                <p>Each project's campaign webpage on Kickstarter lists a city location, and our mapping process assigned each project to the county in which it is located.  The maps here show the aggregate values for all successful projects listed for that county up to 2016.</p>
				<p>Data Dictionary</p>
                <ul>
                    <li>Year (mean) = average year for all projects in county</li>
                    <li>Goal ($, mean) = average $ goal for all projects in county</li>
                    <li>Pledges ($, median) = median $ pledges raised for all projects in county</li>
                    <li>Pledges ($, mean) = mean $ pledges raised for all projects in county</li>
                    <li>County code = County FIPS code</li>
                    <li>Num. backers (mean) = average number of project backers for all projects in county</li>
                    <li>Goal ($, median) = median $ goal for all projects in county</li>
                    <li>Year (median) = median year for all projects in county</li>
                    <li>Goal ($, total) = total $ goal for all projects in county</li>
                    <li>Pledges ($, median) = median $ pledges raised for all projects in county</li>
                    <li>Num. backers (median) = median number of project backers for all projects in county</li>
                    <li>Pledges ($, total) = total $ pledges raised for all projects in county</li>
                </ul>
			</div>
		</div>
	);
}

export default GeoData;