import React, { useState} from "react";
import $ from "jquery";
import ZoomableChart from "../components/Zoomable/ZoomableChart";
import Utils from "../helpers/Data";
import InputArea from "../components/DoubleCriteria/DoubleCriteria";
import Share from "../components/Share/Share";
import DataPageLink from "../components/DataPageLink/DataPageLink";


function ZoomablePanel() {

    const [DP1, setDP1] = useState('');
    const [DP2, setDP2] = useState('');
    const [zoomData, setZoomData] = useState([]);
    const [svgRendered, setSvgRendered] = useState(false);
    const IsSingleCriteria = false;

    const handleClick = ()=>{
        let fatherValue = $('#father').find(":selected").val();
        let notIncludeValue = $('#notInclude').find(":selected").val();

        if(fatherValue!=="" && notIncludeValue !== ""){
            Utils.GetComposeData(fatherValue, notIncludeValue).then(data => {
                let zoom = Utils.pivot;
                setZoomData(zoom);
            });
            
        }
        
    }

    return (
        <div>
            <h1 className='heading p-3'>
                Zoomable Chart
            </h1>
            <div>
                <InputArea handleClick={handleClick} DP1={setDP1} DP2={setDP2} IsSvgRendered={svgRendered} sort={false}></InputArea>
            </div>
            <div>
                <ZoomableChart data ={Utils.GetCustomZoomableData(zoomData)} IsSvgRendered={setSvgRendered}></ZoomableChart>
            </div>
            <div className="shareParent">
                <Share DP1={DP1} DP2={DP2} IsSingleCriteria={IsSingleCriteria}/>
            </div>
            <DataPageLink/>
        </div>
    );

}

export default ZoomablePanel;
