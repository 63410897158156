import React, { useRef, useEffect, useState} from "react";
import CreateZoomableChart from "./Zoomable";
import "./Zoomable.css";
import * as d3 from 'd3';
import Utils from "../../helpers/Data";

function ZoomableChart(props) {  

    const svgRef = useRef();    
    const[total, setTotal] = useState(0);

    useEffect(()=>{
        if(total > 0){
            props.IsSvgRendered(true);
        }
    }, [total])
    
    useEffect(()=>{
        if(props.data != null && props.data.length !== 0){
            d3.selectAll('svg#chart').selectAll('*').remove();
            
            Promise.all([props]).then(data => {
                let zoomableChart = document.querySelector(".zoomableChart");

                if(!zoomableChart){
                    CreateZoomableChart(svgRef, data[0].data, {});
                    setTotal(data[0].total);
                }
            })
        }
    });
    

      return (  
        <>
            <div className="zoom-div">
                <svg ref={svgRef} id="chart"></svg>     
            </div>
        </>
      )  
}  

export default ZoomableChart;
