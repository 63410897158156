import React, { useState, useEffect } from "react";
import Filter from "../components/Filter.js";
import Visualization from "../components/Visualization.js";
import Data from "./../visualizationInventory.js";
import "./../components/barGraphAnimation.js";
import AnimatedBarGraph from "./../components/AnimatedBarGraph.js"
import $ from "jquery";

const Home = () => {
	const [vizData, setVizData] = useState(Data);

	const handleCallback = (childData) => {
        setVizData(childData);
    }

	useEffect(() => {
		$(".bars li .bar").each(function (key, bar) {
			let percentage = $(this).data("percentage");
			$(this).animate(
				{
					height: percentage + "%",
				},
				1000
			);
		});
	})

	return (
		<div className="container-fluid">
			<AnimatedBarGraph/>
			<h1 className='heading p-3 mt-3'>VISUALIZATIONS</h1>
			<Filter homeCallback={handleCallback}/>
			<Visualization data={vizData}/>
		</div>
	);
}

export default Home;
