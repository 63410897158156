/** @format */

import React from "react";
import "./animatedBarGraph.css";

const AnimatedBarGraph = () => {
	return (
		<>
			<div className='chart'>
				<div className='text'>
					Arts Analytics is a project of the Arts, Entrepreneurship, and
					Innovation Lab that puts meaning to data in the arts through data
					visualization to understand the place of arts and culture in public
					life.
				</div>
				<ul className='bars'>
					<li>
						<div className='bar' data-percentage='50'></div>
					</li>
					<li>
						<div
							className='bar'
							style={{ background: "#2596be" }}
							data-percentage='40'></div>
					</li>
					<li>
						<div className='bar' data-percentage='35'></div>
					</li>
					<li>
						<div
							className='bar'
							style={{ background: "#2596be" }}
							data-percentage='45'></div>
					</li>
					<li>
						<div className='bar' data-percentage='65'></div>
					</li>
					<li>
						<div
							className='bar'
							style={{ background: "#2596be" }}
							data-percentage='75'></div>
					</li>
					<li>
						<div className='bar' data-percentage='50'></div>
					</li>
					<li>
						<div
							className='bar'
							style={{ background: "#2596be" }}
							data-percentage='80'></div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default AnimatedBarGraph;
