import React, { useState } from "react";
import $ from "jquery";
import Utils from "../helpers/Data";
import InputArea from "../components/DoubleCriteria/DoubleCriteria";
import GroupedBarChart from "../components/GroupedBar/GroupedBarChart";
import Share from "../components/Share/Share";
import DataPageLink from "../components/DataPageLink/DataPageLink";


function GroupedBarPanel () {

    const [pivotData, setPivotData] = useState([]);
    const [acrossData, setAcrossData] = useState([]);

    const [DP1, setDP1] = useState('');
    const [DP2, setDP2] = useState('');
    const [svgRendered, setSvgRendered] = useState(false);
    const IsSingleCriteria = false;

    const handleClick = ()=>{
        let fatherValue = $('#father').find(":selected").val();
        let notIncludeValue = $('#notInclude').find(":selected").val();

        if(fatherValue!=="" && notIncludeValue !== ""){
            
            Utils.GetComposeData(fatherValue, notIncludeValue).then(() => {
                let pivot = Utils.pivot;
                let across = Utils.across;
                setPivotData(pivot);
                setAcrossData(across);
            });
            
        }
        
    }

    return (
        <div>
            
            <h1 className='heading p-3'>
                Grouped Bar
            </h1>
            <div>
                <InputArea handleClick={handleClick} DP1={setDP1} DP2={setDP2} IsSvgRendered={svgRendered}></InputArea>
            </div>
            <div>
                <GroupedBarChart pivot={pivotData} across={acrossData} IsSvgRendered={setSvgRendered}></GroupedBarChart>
            </div>
            <div className="shareParent">
                <Share DP1={DP1} DP2={DP2} IsSingleCriteria={IsSingleCriteria}/>
            </div>
            <DataPageLink/>
        </div>
    );

}

export default GroupedBarPanel;
