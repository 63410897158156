import React, { useState, useEffect, useRef} from "react";
import $ from "jquery";
import CountyView from "../components/CountyMap/CountyView";
import Share from "../components/Share/Share";
import GeoDataPageLink from "../components/DataPageLink/GeoDataPageLink";
import CountyUtils from "../helpers/CountyHelper";
import SingleDropdownInputArea from "../components/SingleDropdownCriteria/SingleDropdownCriteria";

export default function CountyPanel() {
    const [DP1, setDP1] = useState('');
    const [svgRendered, setSvgRendered] = useState(false);
    const [data, setData] = useState('');
    const [countyData, setCountyData] = useState('');
    const [options, setOptions] = useState([]);
    const [cleanData, setCleanData] = useState([]);
    const [parameterValue, setParameterValue] = useState('');
    const [currentValue, setCurrentValue] = useState('');

    const getData = async () => {
        const res = await fetch('USKSagg.json');
        const dataAsync = await res.json();
        setData(dataAsync);
        const countyRes = await fetch('counties-10m.json');
        const countyDataAsync = await countyRes.json();
        setCountyData(countyDataAsync);
     }

    const handleClick = () => {
        let parameterSelectValue = $('#parameters').find(":selected").val();
        if(parameterSelectValue != currentValue){
            setCurrentValue(parameterSelectValue);
            setParameterValue(parameterSelectValue);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        setOptions(CountyUtils.GetObjectDataKeys(data));
    }, [data])

    useEffect(() => {
        setCleanData(CountyUtils.GetCleanData(data, parameterValue));
    }, [parameterValue])

    return (
        <div>
            <h1 className='heading p-3'>
                Map Chart
            </h1>
            <div>
                <SingleDropdownInputArea handleClick={handleClick} DP1={setDP1} IsSvgRendered={svgRendered} options={options} countyData={countyData}></SingleDropdownInputArea>
            </div>
            <div>
                <CountyView data={cleanData} countyData={countyData} IsSvgRendered={setSvgRendered}></CountyView>
            </div>
            <div className="shareParent">
                <Share DP1={DP1}/>
            </div>
            <GeoDataPageLink/>
        </div>
    );

}